<template>
  <div class="bgContent" @click="close">
    <div class="mainContent">
      <div @click.stop="addWxBgTap">
        <img
          style="
            position: absolute;
            top: 0;
            left: calc((100% - 375px) / 2);
            right: calc((100% - 375px) / 2);
            width: 375px;
            height: 534px;
          "
          src="../../assets/imgs/receiveMaterial/addwx-light.png"
          alt=""
        />
        <img
          style="
            position: absolute;
            top: 84px;
            left: calc((100% - 315px) / 2);
            width: 315px;
            height: 450px;
          "
          src="../../assets/imgs/receiveMaterial/addwx-bg.png"
          alt=""
        />
        <div
          class="addWxContent"
          style="
            top: 84px;
            left: calc((100% - 315px) / 2);
            width: 315px;
            height: 450px;
          "
        >
          <div
            class="qrcode-bg"
            style="
              top: 173px;
              width: 210px;
              height: 210px;
              margin-bottom: 10px;
              border-radius: 6px;
            "
          >
            <img
              style="
                margin: 10px;
                width: 190px;
                height: 190px;
                border-radius: 6px;
              "
              src="../../assets/imgs/receiveMaterial/addwx_qrcode_1.png"
              mode="aspectFit"
              alt=""
            />
          </div>
          <div
            class="longPressTxt"
            style="bottom: 21px; font-size: 12px; line-height: 19px"
          >
            长按识别二维码或截图保存后 <br />
            打开微信识别二维码
          </div>
        </div>
      </div>
      <img
        style="position: absolute; top: 554px; width: 34px; height: 34px"
        src="../../assets/imgs/receiveMaterial/close.png"
        alt=""
        @click="close"
      />
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    close() {
      this.$emit("closeAddWxAction");
    },
    addWxBgTap() {
      console.log("点击背景");
    },
  },
};
</script>

<style scoped>
.bgContent {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 100%;
}

.mainContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
}
.addWxContent {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}
.qrcode-bg {
  position: absolute;
  align-self: center;
  background: linear-gradient(180deg, #fee3ca 0%, #fedcc7 100%);
}
.longPressTxt {
  position: absolute;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #92492a;
  align-self: center;
}
</style>
