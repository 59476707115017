<template>
  <div>
    <div class="top-background">
      <div class="top-content">
        <img class="logo" src="@/assets/imgs/app/logo.png" alt="" />
        <div class="kukebook" :style="'color:' + bookTitleColor">库课图书</div>
        <div class="center"></div>
        <div @click="addWx" v-if="!isMini">
          <img
            class="weixin-icon"
            src="@/assets/imgs/receiveMaterial/weixin-icon.png"
            alt=""
          />
          <div class="weixin-txt" :style="'color:' + addWxTitleColor">
            加微信
          </div>
        </div>
        <div @click="jumpService" v-if="!isMini">
          <img
            class="weixin-service-icon"
            src="@/assets/imgs/receiveMaterial/weixin-service.png"
            alt=""
          />
          <div class="weixin-txt" :style="'color:' + addWxTitleColor">
            咨询客服
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { isOpenWxService } from "@/utils/utils.js";
export default {
  props: {
    bookTitleColor: {
      type: String,
      default: "#000000",
    },
    addWxTitleColor: {
      type: String,
      default: "#333333",
    },
  },
  data() {
    return {
      isMini: false,
    };
  },

  created() {
    const { env } = this.$route.query;
    const userAgent = navigator.userAgent;
    if ((env && env === "native") || /miniProgram/i.test(userAgent)) {
      this.isMini = true;
    }
  },
  methods: {
    // 添加微信好友
    addWx() {
      this.$emit("addWxAction");
    },

    // 添加客服
    jumpService() {
      if (!isOpenWxService("库课图书")) return;
      // 打开客服
      let url = "https://work.weixin.qq.com/kfid/kfc11d9331742f42655";
      window.open(url);
    },
  },
};
</script>

<style scoped>
.top-background {
  width: 100%;
  height: 154px;
}

.top-content {
  display: flex;
  flex-direction: row;
}
.logo {
  width: 88px;
  height: 88px;
  margin-top: 20px;
  margin-left: 40px;
  border-radius: 44px;
}
.kukebook {
  margin-left: 20px;
  margin-top: 43px;
  height: 42px;
  font-size: 28px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  line-height: 42px;
}
.center {
  flex: 1;
}

.weixin-service-icon {
  width: 66px;
  height: 66px;
  margin-top: 20px;
  margin-right: 60px;
  margin-left: 60px;
}

.weixin-icon {
  width: 66px;
  height: 66px;
  margin-top: 20px;
}

.weixin-txt {
  height: 37px;
  font-size: 26px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #333333;
  line-height: 37px;
  align-self: center;
}
</style>
