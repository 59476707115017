<template>
  <div>
    <top-info class="top-info" @addWxAction="addWx"></top-info>
    <div class="extam-title">
      <item-title title="考试类型" :iconSrc="typeIcon"></item-title>
    </div>
    <div class="classify-content">
      <div
        class="classify-item"
        v-for="(value, index) in examTypeList"
        @click="selectExamType = index"
        :key="index"
      >
        <div
          :class="
            smallFont(value.dataName)
              ? 'txt selectTxt font-size-12'
              : 'txt selectTxt font-size-14'
          "
          v-if="selectExamType == index"
        >
          {{ value.dataName }}
        </div>
        <div
          :class="
            smallFont(value.dataName) ? 'txt font-size-12' : 'txt font-size-14'
          "
          v-else
        >
          {{ value.dataName }}
        </div>
      </div>
    </div>
    <item-title title="考试省份" :iconSrc="provinceIcon"></item-title>
    <div class="classify-content">
      <div
        class="classify-item"
        v-for="(value, index) in provinceList"
        v-show="index < 9 || (index >= 9 && isShowLoadMore == false)"
        @click="selectProvince = index"
        :key="value.categoryId"
      >
        <div
          v-if="provinceList.length > 9 && index == 8 && isShowLoadMore == true"
          class="more"
          @click.stop="isShowLoadMore = false"
        >
          <div class="more-txt font-size-14">更多</div>
          <img
            class="more-icon"
            src="@/assets/imgs/receiveMaterial/more-province.png"
            alt=""
          />
        </div>
        <div v-else>
          <div
            :class="
              smallFont(value.dataName)
                ? 'txt selectTxt font-size-12'
                : 'txt selectTxt font-size-14'
            "
            v-if="selectProvince == index"
          >
            {{ value.dataName }}
          </div>
          <div
            :class="
              smallFont(value.dataName)
                ? 'txt font-size-12'
                : 'txt font-size-14'
            "
            v-else
          >
            {{ value.dataName }}
          </div>
        </div>
      </div>
      <div class="bottom-height"></div>
    </div>
    <div class="bottom-bg">
      <div class="receiveMaterial" @click="receiveMaterialAction">领取资料</div>
    </div>
    <!-- 添加微信弹框 start -->
    <van-popup class="popup" v-model="showAddWx" position="center">
      <add-wx @closeAddWxAction="showAddWx = false"> </add-wx>
    </van-popup>
    <!-- 添加微信弹框 end -->

    <!-- 输入手机号弹框 start -->
    <van-popup class="popup" v-model="showInputPhone" position="center">
      <input-phone
        v-if="showInputPhone"
        :examType="examTypeList[selectExamType].dataName"
        :province="provinceList[selectProvince].dataName"
        @closePhoneAction="showInputPhone = false"
      ></input-phone>
    </van-popup>
    <!-- 输入手机号弹框 end -->
  </div>
</template>

<script>
import TopInfo from "./top-info.vue";
import ItemTitle from "./item-title.vue";
import AddWx from "./addWx.vue";
import InputPhone from "./inputPhone.vue";
import { getEnumList } from "../../api/gateway";
import { isMobile, isIpad } from "@/utils/utils.js";
import { Toast } from "vant";

const typeIcon = require("../../assets/imgs/receiveMaterial/exam-type.png");
const provinceIcon = require("../../assets/imgs/receiveMaterial/exam-province.png");
export default {
  components: {
    TopInfo,
    ItemTitle,
    AddWx,
    InputPhone,
  },
  data() {
    return {
      selectExamType: -1,
      selectProvince: -1,
      isShowLoadMore: true,
      examTypeList: [],
      provinceList: [],
      typeIcon: typeIcon,
      provinceIcon: provinceIcon,
      showAddWx: false,
      showInputPhone: false,
    };
  },
  mounted() {
    this.getCategoryInfos();
  },

  methods: {
    /// 请求分类信息
    getCategoryInfos() {
      this.getExamType();
      this.getProvince();
    },
    /// 请求考试类型信息
    async getExamType() {
      let examParam = {
        category: "BUSINESS_TYPE",
        status: ["1"],
      };
      const { code, data } = await getEnumList(examParam);
      if (code != "10000") return;
      this.examTypeList = data["list"] || [];
    },
    /// 请求考试省份数据
    async getProvince() {
      let provinceParam = {
        category: "BUSINESS_REGION",
        status: ["1"],
      };
      const { code, data } = await getEnumList(provinceParam);
      if (code != "10000") return;
      this.provinceList = data["list"] || [];
    },
    // 是否是小字体
    smallFont(dataName) {
      return dataName.length > 7 && isMobile() && !isIpad();
    },
    /// 添加微信好友
    addWx() {
      this.showAddWx = true;
    },
    /// 领取资料
    receiveMaterialAction() {
      if (this.selectExamType === -1) {
        Toast("请先选择考试类型");
        return;
      }
      if (this.selectProvince === -1) {
        Toast("请先选择考试省份");
        return;
      }
      this.showInputPhone = true;
    },
  },
};
</script>

<style scoped>
.top-info {
  position: fixed;
  top: 0;
  width: 100%;
  height: 154px;
  background: #ffffff;
}

.extam-title {
  margin-top: 154px;
}

.font-size-12 {
  font-size: 24px;
}

.font-size-14 {
  font-size: 28px;
}
.classify-content {
  display: flex;
  flex-wrap: wrap;
  margin: 30px 40px 34px 40px;
}
.classify-item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 28px;
  background: #f5f6f9;
  border-radius: 8px;
  margin-right: 26px;
  margin-bottom: 26px;
  width: calc((100% - 52px) / 3);
  height: 80px;
}
.classify-item .txt {
  padding: 8px 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
  text-align: center;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  display: -moz-box;
  -moz-line-clamp: 2;
  -moz-box-orient: vertical;
  white-space: normal;
  overflow: hidden;
  height: 64px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.classify-item .selectTxt {
  color: #f01414;
  text-align: center;
  border: 1px solid #f01414;
  border-radius: 8px;
}

.classify-item .more {
  display: flex;
  flex-direction: row;
  justify-content: center;
  height: 80px;
}
.classify-item .more-txt {
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
  line-height: 80px;
}

.classify-item .more-icon {
  width: 24px;
  height: 25px;
  margin-left: 10px;
  margin-top: 26px;
}
.classify-item:nth-of-type(3n + 0) {
  margin-right: 0;
}
/*  */
.bottom-bg {
  position: fixed;
  bottom: 0;
  width: 100%;
  background: #ffffff;
  height: calc(106px + constant(safe-area-inset-bottom) / 2);
  height: calc(106px + env(safe-area-inset-bottom) / 2);
}
.bottom-height {
  height: 212px;
}
.receiveMaterial {
  margin: 13px 115px;
  padding: 15px;
  box-sizing: border-box;
  background: linear-gradient(90deg, #ff5151 0%, #f61313 100%);
  border-radius: 54px;
  font-size: 36px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #ffffff;
}
.popup {
  background-color: transparent;
  height: 100%;
  width: 100%;
}
</style>
