<template>
  <div class="classify">
    <img class="classify-icon" :src="iconSrc" alt="" />
    <div class="classify-name">{{ title }}</div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: "",
    },
    iconSrc: {
      type: String,
      default: "",
    },
  },
};
</script>

<style scoped>
.classify {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 50px;
}

.classify-icon {
  width: 50px;
  height: 50px;
  margin-left: 42px;
  margin-right: 12px;
}

.classify-name {
  height: 36px;
  font-size: 36px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #333333;
  line-height: 36px;
}
</style>
