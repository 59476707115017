var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('top-info',{staticClass:"top-info",on:{"addWxAction":_vm.addWx}}),_c('div',{staticClass:"extam-title"},[_c('item-title',{attrs:{"title":"考试类型","iconSrc":_vm.typeIcon}})],1),_c('div',{staticClass:"classify-content"},_vm._l((_vm.examTypeList),function(value,index){return _c('div',{key:index,staticClass:"classify-item",on:{"click":function($event){_vm.selectExamType = index}}},[(_vm.selectExamType == index)?_c('div',{class:_vm.smallFont(value.dataName)
            ? 'txt selectTxt font-size-12'
            : 'txt selectTxt font-size-14'},[_vm._v(" "+_vm._s(value.dataName)+" ")]):_c('div',{class:_vm.smallFont(value.dataName) ? 'txt font-size-12' : 'txt font-size-14'},[_vm._v(" "+_vm._s(value.dataName)+" ")])])}),0),_c('item-title',{attrs:{"title":"考试省份","iconSrc":_vm.provinceIcon}}),_c('div',{staticClass:"classify-content"},[_vm._l((_vm.provinceList),function(value,index){return _c('div',{directives:[{name:"show",rawName:"v-show",value:(index < 9 || (index >= 9 && _vm.isShowLoadMore == false)),expression:"index < 9 || (index >= 9 && isShowLoadMore == false)"}],key:value.categoryId,staticClass:"classify-item",on:{"click":function($event){_vm.selectProvince = index}}},[(_vm.provinceList.length > 9 && index == 8 && _vm.isShowLoadMore == true)?_c('div',{staticClass:"more",on:{"click":function($event){$event.stopPropagation();_vm.isShowLoadMore = false}}},[_c('div',{staticClass:"more-txt font-size-14"},[_vm._v("更多")]),_c('img',{staticClass:"more-icon",attrs:{"src":require("@/assets/imgs/receiveMaterial/more-province.png"),"alt":""}})]):_c('div',[(_vm.selectProvince == index)?_c('div',{class:_vm.smallFont(value.dataName)
              ? 'txt selectTxt font-size-12'
              : 'txt selectTxt font-size-14'},[_vm._v(" "+_vm._s(value.dataName)+" ")]):_c('div',{class:_vm.smallFont(value.dataName)
              ? 'txt font-size-12'
              : 'txt font-size-14'},[_vm._v(" "+_vm._s(value.dataName)+" ")])])])}),_c('div',{staticClass:"bottom-height"})],2),_c('div',{staticClass:"bottom-bg"},[_c('div',{staticClass:"receiveMaterial",on:{"click":_vm.receiveMaterialAction}},[_vm._v("领取资料")])]),_c('van-popup',{staticClass:"popup",attrs:{"position":"center"},model:{value:(_vm.showAddWx),callback:function ($$v) {_vm.showAddWx=$$v},expression:"showAddWx"}},[_c('add-wx',{on:{"closeAddWxAction":function($event){_vm.showAddWx = false}}})],1),_c('van-popup',{staticClass:"popup",attrs:{"position":"center"},model:{value:(_vm.showInputPhone),callback:function ($$v) {_vm.showInputPhone=$$v},expression:"showInputPhone"}},[(_vm.showInputPhone)?_c('input-phone',{attrs:{"examType":_vm.examTypeList[_vm.selectExamType].dataName,"province":_vm.provinceList[_vm.selectProvince].dataName},on:{"closePhoneAction":function($event){_vm.showInputPhone = false}}}):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }